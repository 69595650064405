import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { chatUsersData } from 'src/app/models/chat.model';
import { ApiEndpointsService } from 'src/app/services/api-endpoint.service';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private _accountId: string;
  private _chatSubject: any;
  public index: number;
  send_selected_person =  new BehaviorSubject<any>('');
  selected_person = this.send_selected_person.asObservable();
  constructor(private authService: AuthService, private endPointService: ApiEndpointsService,
    private apiHttpService: ApiHttpService, private http: HttpClient) {
    if (this.authService?.userValue != null)
      this._accountId = this.authService.userValue.uid;
    // else
    //   this.authService.logout();
    this._chatSubject = new Subject();
  }
  get chat(): any {
    return this._chatSubject.asObservable();
  }

  set chat(person: any) {
    this._chatSubject.next(person);
  }

  // get index(): any {
  //   return this.selectedIndex.asObservable();
  // }

  // set index(index: any) {
  //   this.selectedIndex(index);
  // }

  async getChatList(queryParams: any): Promise<Observable<chatUsersData>> {
    return this.apiHttpService.get(await this.endPointService.chatList(queryParams)).pipe(map((res: any) => {
      return res;
    }));
  }

  sendMessage(formData: any): Observable<any> {
    return this.apiHttpService.post(this.endPointService.sendMessage(), formData);
  }

  uploadFiles(formData: any): Observable<any> {
    return this.apiHttpService.post(this.endPointService.uploadFiles(), formData);
  }

  async getchatHistory(queryParams: any): Promise<Observable<any>> {


    this.endPointService.signToken(localStorage.getItem('privateKey'), queryParams.uid ?? 'nushift_connect', queryParams);


    
    return this.apiHttpService.get(await this.endPointService.chatHistory(queryParams)).pipe(map(res => {
      if (res.status) return res.data;
      else return [];
    }));
  }

  async getMyChat(userId: string, as: string): Promise<Observable<any>> {
    this.endPointService.signToken(localStorage.getItem('privateKey'), userId ?? 'nushift_connect', {userId: userId});
    return this.apiHttpService.get(await this.endPointService.myChat(userId, as))
      .pipe(map(res => {
        return res;
      }));
  }

  // addReferral(formData: any): Observable<any> {
  //   return this.apiHttpService.post(
  //     this.apiEndPointService.sendReferral(),
  //     formData
  //   );
  // }

  // async addReferral(queryParams: any): Promise<Observable<any>> {
  //   this.endPointService.signToken(localStorage.getItem('privateKey'), queryParams.uid ?? 'nushift_connect', queryParams);
  //   return this.apiHttpService.(this.endPointService.sendMessage(), formData);
  //     .pipe(map(res => {
  //       return res;
  //     }));
  // }
  addReferral(formData: any): Observable<any> {
    this.endPointService.signToken(localStorage.getItem('privateKey'), formData.uid ?? 'nushift_connect', formData);
    return this.apiHttpService.post(this.endPointService.sendReferral(), formData);
  }
}
